import { mapState, mapGetters, mapActions } from 'vuex'

import { Settings, Titles, SchoolData, YearCuttingTypes, Roles, YearTerms, YearSemesters } from '@/utils/enums'

export default {
    data() {
        return {
            staff: [],
            terms: [],
            titles: [],
            levels: [],
            courses: [],
            classes: [],
            parents: [],
            sections: [],
            teachers: [],
            students: [],
            semesters: [],
            activities: [],            
            yearCuttings: [],
            establishments: [],
            repartitionList: [],
            evaluationTypes: [],
            yearCuttingResults: [],
            isCurrentDataLoading: false,
            YearSemesters,
            YearTerms,
            SchoolData
        }
    },
    async created() {

        this.isCurrentDataLoading = true

        var self = this

        this.$_.forIn(YearSemesters, function(value, key) {
          
            self.semesters.push(value)
  
        })
  
        this.$_.forIn(YearTerms, function(value, key) {
            
            self.terms.push(value)
  
        })

        this.$_.forIn(Titles, function(value, key) {
            
            self.titles.push(value)

        })
        
        let resp1 = await this.fetchCoursesDB({ 
            action: 'getCourses',
            params: {
                schoolyear: this.schoolyear
            } 
        })

        if (resp1.success && resp1.data) this.courses = resp1.data

        let resp2 = await this.fetchClassesDB({ 
            action: 'getClasses',
            params: {
                schoolyear: this.schoolyear
            } 
        })

        if (resp2.success && resp2.data) this.classes = resp2.data

        let resp3 = await this.fetchEstablishmentsDB({ 
            action: 'getEstablishments',
            params: {
                schoolyear: this.schoolyear
            } 
        })

        if (resp3.success && resp3.data) this.establishments = resp3.data

        let resp4 = await this.fetchTeachersDB({ 
            action: 'getTeachers',
            params: {
                schoolyear: this.schoolyear
            } 
        })

        if (resp4.success && resp4.data) this.teachers = resp4.data

        let resp5 = await this.fetchStudentsDB({ 
            action: 'getStudents',
            params: {
                schoolyear: this.schoolyear
            } 
        })

        if (resp5.success && resp5.data) this.students = resp5.data

        let resp6 = await this.fetchRepartitionDB({ 
            action: 'getRepartitionList',
            params: {
                schoolyear: this.schoolyear
            } 
        })

        if (resp6.success && resp6.data) this.repartitionList = resp6.data

        let resp7 = await this.fetchEvaluationTypesDB({ 
            action: 'getEvaluationTypes' 
        })

        if (resp7.success && resp7.data) this.evaluationTypes = resp7.data

        let resp8 = await this.fetchYearCuttingsDB({ 
            action: 'getYearCuttings',
            params: {
                schoolyear: this.schoolyear
            }  
        })

        if (resp8.success && resp8.data) this.yearCuttings = this.$_.sortBy(resp8.data, 'start_date')

        let resp9 = await this.fetchParentsDB({ 
            action: 'getParents',
            params: {
                schoolyear: this.schoolyear
            } 
        })

        if (resp9.success && resp9.data) this.parents = resp9.data

        let resp10 = await this.fetchStaffDB({ 
            action: 'getStaff',
            params: {
                schoolyear: this.schoolyear
            } 
        })                

        if (resp10.success && resp10.data) this.staff = resp10.data

        let resp11 = await this.fetchActivitiesDB({ 
            action: 'getActivities',
            params: {
                schoolyear: this.schoolyear
            }
        })                

        if (resp11.success && resp11.data) this.activities = resp11.data

        let resp12 = await this.fetchLevelsDB({ 
            action: 'getLevels',
            params: {
                schoolyear: this.schoolyear
            } 
        })

        if (resp12.success && resp12.data) this.levels = resp12.data

        let resp13 = await this.fetchCourseSectionsDB({ 
            action: 'getCourseSections',
            params: {
                schoolyear: this.schoolyear
            }            
        })

        if (resp13.success && resp13.data) this.sections = resp13.data

        await this.fetchYearCuttingResults()

        this.isCurrentDataLoading = false
        
    },
    computed: {
        ...mapState({
            userData: state => state.session.userData,
            schoolyears: state => state.schoolyears.schoolyears            
        }),        
        ...mapGetters({
            getSettingValue: 'settings/getSettingValue',
            getSchoolData: 'schooldata/getSchoolDataValue'
        }),
        schoolyear() {

            return this.userRole == Roles.SUPERADMIN || this.userRole == Roles.ADMIN 
                                                      ? this.getSettingValue(Settings.ADMIN_SCHOOLYEAR.NAME)
                                                      : this.getSettingValue(Settings.CURRENT_SCHOOLYEAR.NAME)

        },
        userRole() {

            if (this.userData) return parseInt(this.userData.role)
            else return ''

        },
        yearCuttingType() {

            return this.getSchoolData(SchoolData.YEAR_CUTTING_TYPE.NAME)

        },
        isYearCuttingTerm() {
        
            return this.yearCuttingType == YearCuttingTypes.TERM.id

        }        
    },
    methods: {
        ...mapActions({
            fetchEvaluationTypesDB: 'evaluationtypes/fetchEvaluationTypesDB',
            fetchCourseSectionsDB: 'coursesections/fetchCourseSectionsDB',
            fetchEstablishmentsDB: 'establishments/fetchEstablishmentsDB',
            fetchYearCuttingsDB: 'yearcuttings/fetchYearCuttingsDB',
            fetchRepartitionDB: 'repartition/fetchRepartitionDB',
            fetchActivitiesDB: 'activities/fetchActivitiesDB',
            fetchStudentsDB: 'students/fetchStudentsDB',
            fetchTeachersDB: 'teachers/fetchTeachersDB',
            fetchParentsDB: 'parents/fetchParentsDB',
            fetchCoursesDB: 'courses/fetchCoursesDB',
            fetchClassesDB: 'classes/fetchClassesDB',
            fetchLevelsDB: 'levels/fetchLevelsDB',
            fetchStaffDB: 'staff/fetchStaffDB',
        }),
        async fetchYearCuttingResults(){

            let resp = await this.fetchYearCuttingsDB({ 
                action: 'getYearCuttingResults',
                params: {
                    schoolyear: this.schoolyear
                }            
            })
    
            if (resp.success && resp.data) this.yearCuttingResults = resp.data

        },
        getCourseCode(id){
          
            let c = this.courses.find(c => c.id == id)          
  
            return c ? c.code : ''
  
        },
        getCourseName(id){
          
            let c = this.courses.find(c => c.id == id)          
  
            return c ? c.name : ''
  
        },
        getLevelType(id){
          
            let l = this.levels.find(l => l.id == id)          
  
            return l ? l.type : 0
  
        },
        getClassCode(id){
          
            let c = this.classes.find(c => c.id == id)          
  
            return c ? c.code : ''
  
        },
        getClassName(id){
          
            let c = this.classes.find(c => c.id == id)          
  
            return c ? (c.name + ' ' + this.getEstablishmentLocation(c.establishment)) : ''
  
        },
        getClassIncumbentName(id){

            let c = this.classes.find(c => c.id == id)

            return c ? this.getTeacherName(c.incumbent) : ''

        },
        getClassIncumbentPhone(id){

            let c = this.classes.find(c => c.id == id)

            return c ? this.getTeacherPhone(c.incumbent) : ''

        },
        getEstablishmentLocation(id){
            
            let establishment = this.establishments.find(e => e.id == id)          
  
            return establishment ? establishment.location : ''
  
        },  
        getTeacherFirstname(id){
          
            let teacher = this.teachers.find(t => t.id == id)          
  
            return teacher ? teacher.name.split(' ')[0] : ''
  
        },
        getJustTeacherName(id){
          
            let teacher = this.teachers.find(t => t.id == id)          
  
            return teacher ? teacher.name : ''
  
        },
        getTeacherName(id){
          
            let teacher = this.teachers.find(t => t.id == id)          
  
            return teacher ? (this.getTitleLabel(teacher.title) + ' ' + teacher.name) : ''
  
        },
        getTeacherPhone(id){
          
            let teacher = this.teachers.find(t => t.id == id)          
  
            return teacher ? teacher.phone : ''
  
        },
        getTitleLabel(id){
          
            let title = this.titles.find(t => t.id == id)          
  
            return title ? title.label : ''
  
        },
        getStudentName(id){
          
            let student = this.students.find(s => s.id == id)          
  
            return student ? (student.lastname + ' ' + student.firstname) : ''
  
        },
        getParentName(id){
          
            let parent = this.parents.find(p => p.id == id)          
  
            return parent ? parent.name : ''
  
        },
        getParentPhone(id){
          
            let parent = this.parents.find(p => p.id == id)          
  
            return parent ? parent.phone : ''
  
        },
        getParentEmail(id){
          
            let parent = this.parents.find(p => p.id == id)          
  
            return parent ? parent.email : ''
  
        },
        getStaffName(id){
          
            let st = this.staff.find(s => s.id == id)          
  
            return st ? st.name : ''
  
        },
        getEvaluationTypeName(id){

            let evaluationType = this.evaluationTypes.find(t => t.id == id)

            return evaluationType ? evaluationType.name : ''

        },
        getEvaluationTypeCode(id){

            let evaluationType = this.evaluationTypes.find(t => t.id == id)

            return evaluationType ? evaluationType.code : ''

        },
        getEvaluationTypeType(id){

            let evaluationType = this.evaluationTypes.find(t => t.id == id)

            return evaluationType ? evaluationType.type : 0

        },
        getYearCuttingSliceLabel(id){

            let slice = this.getYearCuttingSlice(id)

            return slice ? slice.label : ''
  
        },
        getYearCuttingSlice(id){

            return (this.isYearCuttingTerm ? this.terms : this.semesters).find(e => e.id == id)            
  
        },
        getYearCutting(id){

            return this.yearCuttings.find(t => t.id == id)

        },
        getYearCuttingName(id){

            let yearCutting = this.getYearCutting(id) 
            
            if (yearCutting) return this.getYearCuttingSliceLabel(yearCutting.code)

            return ''

        },
        formatJson(filterVal, jsonData) {
            return jsonData.map(v => filterVal.map(j => {
                
                return v[j]
                
            }))
        },
    },    
}