import { mapState } from 'vuex'

import { Routes, Roles, AppScopes } from '@/utils/enums'

export default{
    created() {

        let mergedRoutes = [] 
        
        this.$router.options.routes.forEach(route => {
        
            if (route.children && route.children.length){
    
              route.children.forEach(r => { mergedRoutes.push(r) })
    
            }
              
            mergedRoutes.push(route)
                    
        })

        let route = mergedRoutes.find(route => route.name == this.$route.name)

        if (route && this.userData){

            let scope = null

            switch (parseInt(this.userData.role)) {

                case Roles.SUPERADMIN:
                  scope = AppScopes.ADMIN
                  break

                case Roles.ADMIN:
                  scope = AppScopes.ADMIN
                  break

                case Roles.TEACHER:                  
                  scope = AppScopes.TEACHER
                  break

                case Roles.STUDENT:                  
                  scope = AppScopes.STUDENT
                  break

                case Roles.PARENT:                  
                  scope = AppScopes.PARENT
                  break
                          
            }
            
            if (!route.scopes.includes(AppScopes.GLOBAL) && (!scope || !route.scopes.includes(scope))) this.$router.replace(Routes.LOGIN.PATH)

        }

    },
    computed:{
        ...mapState({
            userData: state => state.session.userData,            
        }),
    }
}